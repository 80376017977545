<template>
   <div class="">
       <searchBar :searchInput="false"></searchBar>
		<div class="title bgf flex">
			<div class="fs_16 color_333 flex_start">
				<span>完税申请</span>
			</div>
		</div>
        <div class="content bgf">
            <div class="tips fs_20 bold">为了服务双方的安全，在完税申请前请依次完成以下步骤</div>
            <div class="step flex_cen">
                <div class="item" :class="{in: stepNum>=0}">
                    <div class="num fs_18 bold txt flex_cen line" :class="{in: stepNum>=1}">1</div>
                    <div class="info center">
                        <img src="@/assets/imgs/auth.png" class="icon" alt="">
                        <img src="@/assets/imgs/check.png" class="check" alt="" v-if="stepNum>0">
                        <div class="fs_18 step-title bold">企业认证</div>
                    </div>
                    <div class="flex_cen">
                        <el-button type="primary" class="fs_16 btn" v-if="userInfo.company_status === 'no_apply'" @click="authCompany">立即认证</el-button>
                        <el-button type="primary" class="fs_16 btn" disabled v-else-if="userInfo.company_status === 'apply'">信息审核中</el-button>
                        <el-button type="primary" class="fs_16 btn" disabled v-else-if="userInfo.company_status === 'normal'">已认证企业</el-button>
                    </div>
                </div>
                <div class="item" :class="{in: stepNum>=1}">
                    <div class="num fs_18 bold txt flex_cen line" :class="{in: stepNum>=2}">2</div>
                    <div class="info center">
                        <img src="@/assets/imgs/agr.png" class="icon" alt="">
                        <img src="@/assets/imgs/check.png" class="check" alt="" v-if="stepNum>1">
                        <div class="fs_18 step-title bold">签署协议</div>
                    </div>
                    <div class="flex_cen">
                        <el-button type="primary" class="fs_16 btn" :disabled="stepNum!=1" @click="handleClick('authBookDialog')">{{ stepNum>=2 ? '已' : '' }}签署协议</el-button>
                    </div>
                </div>
                <div class="item" :class="{in: stepNum>=2}">
                    <div class="num fs_18 bold txt flex_cen">3</div>
                    <div class="info center">
                        <img src="@/assets/imgs/reday1.png" class="icon" alt="">
                        <img src="@/assets/imgs/check.png" class="check" alt="" v-if="stepNum>2">
                        <div class="fs_18 step-title bold">阅读须知</div>
                    </div>
                    <div class="flex_cen">
                        <el-button type="primary" class="fs_16 btn" :disabled="stepNum!=2" @click="$refs.agreement.visible=true">阅读须知</el-button>
                    </div>
                </div>
            </div>
        </div>

        <auth-book-dialog title="协议文件" :tmpImg="require('@/assets/imgs/auth-book.png')" @upload="upload" ref="authBookDialog"></auth-book-dialog>
        <agreement ref="agreement"></agreement>
   </div>
</template>

<script>
import { l_getItem, l_setItem } from "@/common/cache"
import authBookDialog from '@/components/authBook-dialog'
import agreement from './components/agreement'
import { mapActions } from "vuex"
export default {
	name: 'TaxesAuth',
	components: { authBookDialog, agreement },
	data() {
		return {
			dialogTableVisible: false,
			stepNum: 0,
			userInfo: {}
		}
	},
	created() {
		this.userInfo = l_getItem("USERINFO") || {}
		let company = l_getItem("COMPANY") || {}
		if(this.userInfo.company_status && this.userInfo.company_status !== 'no_apply') {
			this.stepNum ++
		}
		if(company.taxes_agreement) {
			this.stepNum ++
		}
	},
	methods: {
		...mapActions({
			setCompanyInfo: "SETCOMPANY"
		}),
		authCompany() {
			this.$router.push('/create')
		},
		handleClick(ele) {
			this.$refs[ele].dialogVisible = true
		},
		upload(file) {
			this.$axios({
				url: "/ent/v3/taxes/import/agreement",
				method: "POST",
				data: {
					file
				},
				shake: true
			}).then(res => {
				if (res.code === 200) {
					let company = l_getItem("COMPANY") || {}
					company.taxes_agreement = res.data.file
					this.setCompanyInfo(company)
					l_setItem('COMPANY', company)
					this.stepNum ++
					this.$message.success("上传成功")
					this.$refs.authBookDialog.dialogVisible = false
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
@color: #1890FF;
@subcolor: #D7DEEC;

.title {
	padding: 20px 40px 20px 54px;
	border-radius: 2px;

	.post-icon {
		width: 22px;
		height: 22px;
		// margin-right: 10px;
	}
}
.content {
    margin-top: 10px;
    min-height: 71vh;
    padding-bottom: 60px;
    /deep/.el-button--primary.is-disabled {
        background-color: @subcolor;
    }
    .d-color {
        color: @subcolor;
    }
    .tips {
        line-height: 95px;
        background: #A2AEC7;
        color: #fff;
        text-align: center;
        margin-bottom: 80px;
    }
    .item {
        width: 212px;
        margin: 0 22px;

        .num {
            width: 32px;
            height: 32px;
            background: @subcolor;
            border-radius: 100%;
            margin: 0 auto;
            &.line {
                position: relative;
                &::before {
                    position: absolute;
                    width: 220px;
                    height: 1px;
                    content: '';
                    right: -222px;
                    top: 50%;
                    border-bottom: 1px dashed @subcolor;
                }
            }
        }
        .info {
            width: 212px;
            height: 260px;
            border-radius: 24px;
            border: 1px dashed @subcolor;
            margin: 30px 0 35px;
            padding: 32px 22px;
            position: relative;
            .icon {
                width: 148px;
                height: 148px;
                margin: 0 auto;
            }
            .step-title {
                color: @subcolor;
            }
            .check {
                position: absolute;
                width: 38px;
                height: 38px;
                bottom: -19px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                // display: none;
            }
        }
        .btn {
            width: 133px;
            height: 40px;
            border: none;
        }
        &.in {
            .num {
                background: @color;
            }
            .info {
                border-color: @color;
            }
            .step-title {
                color: @color;
            }
            .line.in::before {
                border-color: @color;
            }
        }
    }
}

</style>