<template>
    <el-dialog title=" " v-model="visible" width="550px" top="20vh" :lock-scroll="true">
        <div class="dialog">
            <div class="flex_cen">
                <img src="@/assets/imgs/reday.png" class="reday" alt="">
            </div>
            <div class="center fs_18 bold mb_15">完税须知</div>
            <el-checkbox-group v-model="checkList">
                <div class="flex_c agr-item" v-for="(item, index) in taxesRule" :key="index">
                    <el-checkbox :label="item"></el-checkbox>
                </div>
            </el-checkbox-group>
            <div class="flex_cen mt_15">
                <el-button type="primary" class="fs_16 btn" :disabled="checkList.length<taxesRule.length" @click="next">我已知晓 下一步</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { l_getItem } from "@/common/cache"
export default {
	data() {
		return {
			checkList: [],
			visible: false,
			taxesRule: [
				'1、确认交易真实且有对应的合作协议',
				'2、确认双方有对应金额的银行转帐记录',
				'3、确认提供的完税资料完整且准确',
				'4、确认交易双方没有任何工商关联',
				'5、确认本企业将承担以上偏差导致的责任',
				'6、同意授权职路数字平台及其指定的第三方为本次交易提供薪税服务'
			]
		}
	},
	props: {
		router: {
			default: '/payTaxes/list',
			type: String
		}
	},
	methods: {
		next() {
			this.visible = false
			let userInfo = l_getItem("USERINFO") || {}
			if(userInfo.company_status === 'apply') {
				this.$confirm('企业信息正在审核中，请耐心等待', '提示', {
					confirmButtonText: '知道了',
					cancelButtonText: '取 消',
					showCancelButton: false,
					type: 'warning'
				}).then(() => {
					this.$router.push(`/index`)
				})
			}else {
				this.$router.push(this.router)
			}
		}
	}
}
</script>

<style lang='less' scoped>
@subcolor: #d7deec;
.dialog {
    .reday {
        width: 94px;
        height: auto;
        margin: -90px 0 23px 30px;
    }
    .el-checkbox-group {
        min-height: 24vh;
    }
    /deep/.el-checkbox__inner {
        width: 16px;
        height: 16px;
        &::after {
            top: 2px;
            left: 5px;
        }
    }
    .agr-item {
        padding: 15px 0;
        border-bottom: 1px solid @subcolor;
    }
    .btn {
        height: 48px;
    }
}
</style>